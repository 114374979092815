
.page-report-container{
    padding: 0px 45px 45px 45px;

    .title-home-screen {
        display: flex;
        padding: 7px;

        h1{
            margin-top: 15px;
        }
    }

    h1{
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 42px;
        color: #44474A;
    }

    .tableData {
        padding: 30px;
        margin-top: 30px;
        background: #ffffff;
        box-shadow: 0px 14px 16px rgba(0, 0, 0, 0.11);
        border-radius: 4px;

        .filtrs{
            form{
                display: flex;
                align-items: center;
                margin-bottom: 20px;
            }

            .filtro{
                margin-right: 20px;

                label {
                    margin-left: 10px;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    color: var(--dark);
                }

                .campos {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    -webkit-appearance: none;
                    background: var(--white);
                    border: 1px solid #E5E5E5;
                    box-sizing: border-box;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 6px;
                    // width: 111px;
                    // height: 29px;
                    margin: 10px;
                    padding: 7px 0 7px 10px;
                    outline: none;

                }
            }

            button{
                display: flex;
                align-items: center;
                padding: 7px 0;
                background: #0f6eb4;
                border-radius: 4px;
                font-family: Roboto;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
                color: #fff;
                border: none;
                width: 125px;
                text-align: center;
                span{
                    margin: 0 7px 0 9px;

                    &.material-icons.white{
                        color: #FFFFFF;
                    }
                }

                &:hover{
                    cursor: pointer;
                }
            }
        }

        .field-select {
            display: flex;
            position: relative;
            // width: 11%;
            margin-bottom: 20px;

            .fields {
                &:hover {
                    cursor: pointer;
                }
                font-family: "Roboto";
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                color: #50555a;
                -webkit-appearance: none;
                background: var(--white);
                border: 1px solid #e5e5e5;
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 6px;
                margin: 10px;
                padding: 7px 0 5px 7px;
                outline: none;
                width: 100%;
            }

            .select-icon {
                pointer-events: none;
                position: absolute;
                right: 16px;
                top: 21px;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .table{
            display: flex;
            width: 100%;

            table{
                thead{
                    border: none;
                    tr{
                        th{
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: bold;
                            font-size: 16px;
                            color: var(--dark);
                            border: none;
                        }
                    }
                }

                tbody{
                    border: none;
                    tr{
                        td{
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            color: var(--dark);
                            border: none;
                        }

                        &:nth-child(even) {
                            background-color: #eaeef0;
                        }
                    }
                }
            }

            .table1{
                width: 50%;
                table {
                    width: 100%;
                    thead{
                        tr{
                            th{
                                &:hover{
                                    cursor: pointer;
                                }
                                img{
                                    width: 18px;
                                    transform: rotate(0deg);
                                    transition: all 0.5s ease-in-out;
                                    &.sortIcon {
                                    transform: rotate(180deg);
                                    }
                                }
                            }
                        }
                    }

                    tbody{
                        tr{
                            td{
                                &:nth-child(1){
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            .table2{
                width: 50%;
                overflow: auto;

                thead{
                    tr{
                        th{
                            min-width: 350px;
                            &:hover{
                                cursor: pointer;
                            }
                            img{
                                width: 18px;
                                transform: rotate(0deg);
                                transition: all 0.5s ease-in-out;
                                &.sortIcon {
                                transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }
        }

        .finishTable {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            h3 {
                font-family: Roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: #50555a;
            }

            .paginator ::ng-deep .ngx-pagination .current {
                background: #FF9100;
                border-radius: 4px;
                color: #FFFFFF;
            }
            .paginator ::ng-deep .ngx-pagination li {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
            }

            button {
                background: #1c3f9b;
                color: var(--white);
                font-family: "Roboto";
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                border-radius: 4px;
                border: none;
                height: 38px;
                padding: 10px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .title-empty{
            text-align: center;
            h3{
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                color: var(--dark);
            }
        }
    }
}